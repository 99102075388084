import * as React from "react";
import { graphql } from "gatsby";
import {
  Layout, SEO, CBreadCrumb, LWrap, CBtnList, NavAbout
} from "../../components/_index"
import { size } from "lodash";
import { setLang } from "../../utils/set-lang"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <section className="l_sect02">
        <LWrap>
          <h1 className="c_headingLv2">ご利用規約</h1>
          <CBtnList data={[
            {
              label: 'サイト利用規約',
              link: {
                href: '/agreement/terms/',
              },
            },
            {
              label: '宿泊予約システム利用規約',
              link: {
                href: '/agreement/reserve_terms/',
              },
            },
            {
              label: 'オンラインショップシステム利用規約',
              link: {
                href: '/agreement/onlineshop_terms/',
              },
            },
          ]} />
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`